@import 'variables/colors';

.mat-mdc-tab-label-container {
  border-bottom: 1px solid $dark-grey-light;
}

.mat-mdc-tab-labels {
  [role='tab'] {
    .mdc-tab__content .mdc-tab__text-label {
      font-weight: 700;
      color: $darkest-blue;
    }

    .mdc-tab-indicator__content {
      border-color: $green-blue;
    }
  }
}

