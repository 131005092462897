@mixin media-breakpoint-down($dimension) {
	@media only screen and (max-width: $dimension) {
		@content;
	}
}

@mixin media-breakpoint-up($dimension) {
	@media only screen and (min-width: $dimension) {
		@content;
	}
}

@mixin centralize() {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin flex-box($direction, $align: null, $justify: null) {
	display: flex;
	flex-direction: $direction;

	@if $align {
		align-items: $align;
	}

	@if $justify {
		justify-content: $justify;
	}
}

@mixin absolute-horizontal-align() {
	right: 0;
	left: 0;
	margin-right: auto;
	margin-left: auto;
}


@mixin truncate() {
	display: inline-block;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
  }