$border-radius-xxxl: 45px;
$border-radius-xxl: 30px;
$border-radius-xl: 25px;
$border-radius-xlg: 18px;
$border-radius-lg: 16px;
$border-radius-15: 15px;
$border-radius-md: 12px;
$border-radius-sm: 10px;
$border-radius-xs: 8px;
$border-radius-xxs: 5px;
$border-radius-4: 4px;
$border-radius-3: 3px;
$border-radius-1: 1px;
$line-thickness: 2px;
