/* Variables */
@import 'variables/colors';
@import 'variables/margins';
@import 'variables/media-queries';
@import 'variables/paddings';
@import 'variables/palettes';
@import 'variables/theme';
@import 'variables/font-sizes';
@import 'variables/border-radiuses';

/* Component */
@import 'components/mat-menu';
@import 'components/mat-card';
@import 'components/mat-tab';
@import 'components/mat-table';
@import 'components/mat-dialog';
@import 'components/mat-autocomplete';
@import 'components/quill';
@import 'components/mat-chip';

/* Abstract */
@import 'abstracts/mixins';
