@use '../../../node_modules/@angular/material/theming' as mat;
@import 'palettes';

$red: mat.mat-color($mat-red, 500);
$red-error: #ff4154;
$light-grey: mat.mat-color($mat-light-grey, 200);
$mild-grey: mat.mat-color($mat-light-grey, 900);
$sterling-grey: mat.mat-color($mat-dark-grey, 200);
$icon-grey: mat.mat-color($mat-dark-grey, 300);
$white: mat.mat-color($mat-smoke-white, A100);
$grey: mat.mat-color($mat-dark-grey, 400);
$milder-grey: mat.mat-color($mat-mild-grey, 500);
$dark-grey: mat.mat-color($mat-dark-grey, 500);
$very-dark-grey: mat.mat-color($mat-dark-grey, 600);
$icon-grey-light: mat.mat-color($mat-dark-grey, 200);
$dark-grey-light: mat.mat-color($mat-dark-grey, 100);
$dark-grey-highlight: mat.mat-color($mat-dark-grey, 50);
$disabled-grey: mat.mat-color($mat-light-grey, 500);
$green: mat.mat-color($mat-green, 500);
$black: mat.mat-color($mat-black, 500);
$light-black: mat.mat-color($mat-black, 300);
$dark-black: mat.mat-color($mat-black, 800);
$mild-black: mat.mat-color($mat-black, 100);
$medium-grey: mat.mat-color($mat-light-grey, 400);
$smoke-white: mat.mat-color($mat-smoke-white, 500);
$mild-red: mat.mat-color($mat-mild-red, 500);
$white-50: mat.mat-color($transparent-white, 50);
$white-20: mat.mat-color($transparent-white, 20);
$white-10: mat.mat-color($transparent-white, 10);
$box-shadow-11: mat.mat-color($shadow, 11);
$box-shadow-13: mat.mat-color($shadow, 13);
$link-blue: #5cb2e3;
$light-blue: mat.mat-color($mat-blue, 100);
$mild-blue: mat.mat-color($mat-blue, 200);
$blue: mat.mat-color($mat-blue, 500);
$sterling-blue: mat.mat-color($mat-blue, A400);
$darkest-blue: #2b4257;
$hover-gray: rgb(255 255 255 / 15%);
$royal-blue: #1c1c27;
$blue-gray: mat.mat-color($mat-blue, 300);
$green-blue: #45a29e;
$dark-green-blue: #307c78;
$accent-blue: #5bd4cc;
$conversation-blue: #c5cae9;
$online-user: #92c353;
$offline-user: #c4314b;
$close-red: #ff6a79;
$pink: #ed1e79;
$light-pink: #ea93b9;
$chat-bubble-light-grey: #e2e2e2;
$light-grey-border: #727a93;
$box-shadow: rgb(0 0 0 / 5%);
$odd-element: #cbc7c7;
$sun-chocolate: #d2691e;

// Dark theme colors
$charcoal-blue: #3c3c54;
$light-charcoal-blue: #6b6b8a;
$space-blue: #313145;
$arsenic: #424242;
$dark-charcoal: #303030;
$amber: #ffbf00;
