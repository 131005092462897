.cdk-overlay-container {
  .mdc-tooltip .mdc-tooltip__surface {
    max-width: fit-content;
    background: rgb(69 79 104 / 90%);
  }

  .cdk-overlay-dark-backdrop {
    background-color: rgb(0 0 0 / 60%);
  }
}

.cdk-global-overlay-wrapper {
  .mat-mdc-dialog-container {
    .mat-mdc-dialog-surface {
      border-radius: 18px;
    }
  }
}

.dark-mode {
  .cdk-global-overlay-wrapper {
    .mat-mdc-dialog-container {
      .mat-mdc-dialog-surface {
        background-color: #313145;
      }
    }
  }
}
