$margin-xxxl: 60px;
$margin-xxl: 40px;
$margin-xl: 30px;
$margin-xlg: 25px;
$margin-lg: 20px;
$margin-md: 15px;
$margin-sm: 10px;
$margin-xs: 8px;
$margin-xxs: 5px;
$margin-45: 45px;
$margin-35: 35px;
$margin-18: 18px;
$margin-16: 16px;
$margin-14: 14px;
$margin-12: 12px;
$margin-10: 10px;
$margin-8: 8px;
$margin-6: 6px;
$margin-5: 5px;
$margin-4: 4px;
$margin-3: 3px;
$margin-2: 2px;
$margin-1: 1px;
