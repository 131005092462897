.emoji-mart {
  /* stylelint-disable-next-line declaration-no-important */
  width: 355px !important;
  border: 0;
}

.dark-emoji-mart {
  .emoji-mart {
    background-color: #3c3c54;

    .emoji-mart-category-label span {
      color: #fff;
      background-color: rgb(60 60 84 / 95%);
    }

    .emoji-mart-search input {
      color: #fff;
      background: #313145;
      border: 1px solid #9da9b9;

      &::placeholder {
        color: #9da9b9;
      }
    }

    .emoji-mart-search .emoji-mart-search-icon svg {
      filter: invert(69%) sepia(35%) saturate(138%) hue-rotate(175deg) brightness(93%) contrast(85%);
    }

    .emoji-mart-scroll::-webkit-scrollbar-thumb {
      background-color: #fff;
      border: 3px solid #3c3c54;
    }

    .emoji-mart-scroll::-webkit-scrollbar-track {
      background-color: #3c3c54;
    }
  }
}

.emoji-mart .emoji-mart-emoji span {
  max-width: 1.3em;
  line-height: 1;
  cursor: pointer;
}

.emoji-mart .ngx-emoji {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 0;
}

.emoji-mart .emoji-mart-anchors {
  padding: 0;
}

.emoji-mart-preview-skins {
  top: -380%;
  right: 23px;
}

.emoji-mart-skin-swatches {
  padding: $padding-3 1px;
  cursor: pointer;
}

.emoji-mart-preview[hidden] {
  display: block;
  height: 88.4px;
}

.emoji-mart-preview-emoji {
  display: none;
}

.emoji-mart .emoji-mart-category[style*='block'] {
  /* stylelint-disable-next-line declaration-no-important */
  display: grid !important;
}

.emoji-mart .emoji-mart-search {
  padding: 0 $padding-md;
  margin: $margin-sm 0 $margin-xxs;
}

.emoji-mart .emoji-mart-search input {
  padding: $padding-xxs $padding-md $padding-xxs $padding-xxxl;
  font: inherit;
  font-size: 14px;
  line-height: 17px;
  color: $grey;
  border-radius: 18px;

  &:focus {
    border: 1px solid $dark-grey;
  }
}

.emoji-mart .emoji-mart-search .emoji-mart-search-icon {
  top: -3px;
  right: auto;
  left: 30px;
  padding: 0 $padding-xxs;
}

.emoji-mart .emoji-mart-search .emoji-mart-search-icon svg {
  display: block;
  width: 16px;
  height: 36px;
}

.emoji-mart .emoji-mart-category-label span {
  padding: $padding-xxs 0;
  font-size: 14px;
  font-weight: 400;
  color: $darkest-blue;
  text-align: left;
}

.emoji-mart-category {
  display: grid;
  grid-template-columns: repeat(7, minmax(40px, 1fr));
  grid-gap: 4px;
  align-items: center;
  justify-content: space-between;
  padding: 0 $padding-md $padding-xs;
}

.emoji-mart .emoji-mart-category-label {
  grid-column: 1/-1;
}

.emoji-mart-category .emoji-mart-emoji:hover::before {
  width: 96%;
  height: 101%;
  margin-top: -1.7px;
  margin-left: -0.8px;
  border: 2px solid #c7c7c7;
  border-radius: 18%;
}

.emoji-mart-category .emoji-mart-emoji {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 0;
}

.emoji-mart-category .emoji-mart-emoji span {
  /* stylelint-disable-next-line declaration-no-important */
  display: flex !important;
  align-items: center;
  justify-content: center;
  /* stylelint-disable-next-line declaration-no-important */
  width: auto !important;
  min-width: 0;
  /* stylelint-disable-next-line declaration-no-important */
  height: auto !important;
}

.emoji-mart-bar:first-child {
  margin: 0 $margin-md;
}

.emoji-mart-anchor-selected .emoji-mart-anchor-bar {
  height: 1px;
}

.emoji-mart-anchor {
  bottom: -1px;
  cursor: pointer;
}

.emoji-mart .emoji-mart-anchors .emoji-mart-anchor {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $padding-xs 0;
}

.emoji-mart-bar button {
  overflow: hidden;
}

.emoji-mart-anchors svg,
.emoji-mart-anchors img {
  display: block;
  height: 16px;
}

.post-reaction .emoji-mart-emoji-native {
  border: none;
}

.icon-container-reaction .emoji-mart-emoji-native {
  border: none;
}

.default-reaction .emoji-mart-emoji-native {
  border: none;
}

/* TODO: Make sure emoji mart scrollbar is working in all browsers */

/* Customize scrollbar
Not supports in Firefox and IE */

.emoji-mart-scroll::-webkit-scrollbar {
  width: 12px;
  background-color: $white;
}

.emoji-mart-scroll::-webkit-scrollbar-track {
  background-color: $white;
}

.emoji-mart-scroll::-webkit-scrollbar-thumb {
  height: 30px;
  background-color: $sterling-grey;
  border: 4px solid $white;
  border-radius: 16px;
}

.emoji-mart-scroll::-webkit-scrollbar-button {
  display: none;
}

.emoji-mart-scroll {
  height: 245px;
}
