@import 'variables/margins';
@import 'variables/paddings';
@import 'variables/colors';
@import 'variables/font-sizes';

.mention-item {
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-around;

  .mention-details {
    width: 80%;
  }

  .mention-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.875rem;
    height: 1.875rem;
    font-size: 1.875rem;
    color: $sterling-blue;
    border-radius: 50%;
  }

  .mention-value {
    font-size: $font-size-sm;
    color: $darkest-blue;
  }

  .mention-job-title {
    font-size: $font-size-xxs;
    color: $grey;
  }
}

.mention {
  padding: 0;
  font-family: 'Nunito', 'Helvetica Neue', sans-serif;
  font-weight: 800;
  color: $green-blue;
  transition: all 0.25s ease-out;

  &:hover {
    color: $dark-green-blue;
  }
}

.ql-mention-list-item {
  padding: $padding-xs $padding-2;
  line-height: 18px;
  transition: all 0.25s ease-out;

  &:hover {
      color: $dark-green-blue;
  }
}

.ql-mention-denotation-char {
  font-family: 'Nunito', 'Helvetica Neue', sans-serif;
  font-weight: 800;
  color: $green-blue;
  transition: all 0.25s ease-out;

  &:hover {
      color: $dark-green-blue;
  }
}

.ql-snow .ql-tooltip input[type='text'] {
  color: $charcoal-blue;
}

.ql-snow .ql-editor a {
  text-decoration: none;
}

.ql-editor {
  padding-left: 0;
}

.ql-mention-denotation-char + a {
  color: $green-blue;
  text-decoration: none;
  transition: all 0.25s ease-out;

  &:hover {
      color: $dark-green-blue;
  }
}

.ql-mention-list-container {
  border: none;
}

.ql-mention-list-container::-webkit-scrollbar {
  width: 10px;
  background-color: $mild-grey;
}

.ql-mention-list-container::-webkit-scrollbar-thumb {
  background-color: $mild-grey;
  border-radius: $border-radius-xs;
}

.ql-mention-list-container::-webkit-scrollbar-track {
  background-color: $light-grey;
}

.ql-editor.ql-blank::before {
  padding: 0;
  margin-left: calc(0px - $margin-xxs);
  font-style: normal;
}

.loading-spinner {
  width: 24px;
  height: 24px;
  margin: $margin-sm auto $margin-sm;
  border: 4px solid rgb(0 0 0 / 10%);
  border-left-color: $green-blue;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ql-container {
  max-height: 175px;
  overflow-y: scroll;
}

.ql-container.ql-bubble:not(.ql-disabled) a {
  pointer-events: none;
}

