@import 'variables/margins';
@import 'variables/paddings';
@import 'variables/colors';
@import 'variables/font-sizes';

.emoji-menu + * {
  .mat-mdc-menu-panel.mat-mdc-menu-panel {
    max-width: 355px;
  }
}

.mat-menu-options + * {
  .cdk-overlay-pane {
    margin: $margin-xs 0;
  }

  .mat-mdc-menu-panel.mat-mdc-menu-panel {
    border-radius: 15px;
    box-shadow: 0 0 8px rgb(0 0 0 / 10%);

    .mat-mdc-menu-content {
      padding: 5px 0;
    }
  }
}

.reactions-dialog-options-menu + * {
  .mat-mdc-menu-panel {
    max-height: 300px;
    border-radius: 0 10px 10px;

    .mat-mdc-menu-content {
      display: flex;
      flex-direction: column;
      padding: $padding-xxs $padding-xs;
      color: $black;
    }
  }
}

.nav-button-menu + * {
  .mat-mdc-menu-panel {
    margin-top: 10px;
  }

  .mat-mdc-menu-content {
    padding: 0;
  }
}
