$font-size-xxlg: 4rem;
$font-size-xlg: 2.5rem;
$font-size-lg: 2rem;
$font-size-xmd: 1.5rem;

// 16px
$font-size-md: 1rem;

// 14px
$font-size-sm: 0.875rem;

// 13px
$font-size-xs: 0.815rem;

// 12px
$font-size-xxs: 0.75rem;

// 10px
$font-size-xxsm: 0.625rem;
