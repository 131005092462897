/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import 'main';
@import 'node_modules/@ctrl/ngx-emoji-mart/picker';
@import 'components/emoji-mart';
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';
@import 'quill/dist/quill.bubble.css';
@import 'node_modules/quill-mention/dist/dist/quill.mention.css';

@include mat.core;

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

input,
textarea,
select,
button {
  font-family: 'Nunito', 'Helvetica Neue', sans-serif;
}

.mat-icon {
  font-family: 'Material Symbols Rounded', serif;
}

.mat-icon-fill {
  font-variation-settings: 'FILL' 1;
}

.wf-loading body {
  display: none;
}

.required::after {
  content: '*';
}

.dot {
  width: 0.5rem !important;
  height: 0.5rem !important;
  font-size: 0.5rem !important;
  line-height: 0.5rem !important;
}

.icon-1rem {
  width: 1.125rem !important;
  height: 1.125rem !important;
  font-size: 1.125rem !important;
  line-height: 1.125rem !important;
}

.dialog-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.truncate-expand {
  padding: 0;
  margin-left: $margin-2;
  font-size: inherit;
  color: $link-blue;
  white-space: nowrap;
  cursor: pointer;
  background: 0;
  border: 0;
  transition: color 0.2s ease-out;

  &:hover {
    color: $grey;
  }
}

.truncate-text {
  @include truncate;
}

.link {
  color: $link-blue;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

::-webkit-scrollbar {
  width: 12px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  min-height: 35px;
  background: rgb(0 0 0 / 30%);
  background-clip: padding-box;
  border: 3px solid rgb(0 0 0 / 0%);
  border-radius: 8px;

  &:hover {
    background: rgb(0 0 0 / 55%);
    background-clip: padding-box;
    border: 3px solid rgb(0 0 0 / 0%);
  }
}

.action-container {
  @include flex-box(flex, center, center);

  gap: 8px;
  font-size: 16px;
  color: $darkest-blue;

  .reaction-text,
  .action-text {
    font-size: 14px;
    font-weight: 700;
  }
}

.image-preload {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: skeleton-loading 0.5s infinite linear alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200deg 20% 80%);
  }

  100% {
    background-color: hsl(200deg 20% 95%);
  }
}

@include mat.all-component-typographies($dark-theme);

.dark-mode {
  @include mat.all-component-colors($dark-theme);

  .mat-mdc-menu-content.mdc-list {
    background-color: $charcoal-blue;

    .mdc-button > .mat-mdc-button-persistent-ripple {
      background-color: $charcoal-blue;

      &:hover {
        background-color: inherit;
      }
    }
  }

  mat-option.mdc-list-item--selected {
    span.mdc-list-item__primary-text {
      color: var(--mdc-theme-text-primary-on-background) !important;
    }
  }

  .mat-mdc-card,
  .mdc-card {
    background: $space-blue;
  }

  .mat-mdc-tab-labels {
    color: $white;

    [role='tab']:not(.mdc-tab--active) {
      .mdc-tab__content {
        .mdc-tab__text-label {
          color: $sterling-grey;
        }
      }
    }

    [role='tab'] {
      .mdc-tab__content .mdc-tab__text-label {
        color: $white;
      }

      .mdc-tab-indicator__content {
        border-color: $white;
      }
    }
  }

  .mdc-tab--active {
    .mdc-tab__text-label {
      color: $white;
    }
  }

  [role='tab']:not(.mdc-tab--active) {
    .mdc-tab__text-label {
      color: $grey;
    }
  }

  .mdc-tab-indicator__content--underline {
    border-color: $white;
  }

  .community-landing-page-tabs {
    .mat-mdc-tab-labels {
      background-color: inherit;
    }
  }

  .profile .mat-mdc-tab-group {
    .mat-mdc-tab-header {
      background-color: inherit;
    }
  }

  * {
    color: $white;
  }

  .action-container {
    color: $white;
  }

  @keyframes skeleton-loading {
    0% {
      background-color: $grey;
    }

    100% {
      background-color: $arsenic;
    }
  }
}

.community-settings-overlay {
  display: flex;
  width: 100%;
  height: fit-content;
  max-height: 550px;
  padding: $padding-md $padding-xxxl;
  margin-top: $margin-xxs;
  overflow-y: scroll;
  background-color: $white;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 5%) 0 0 15px 0;
  animation: fade-in ease-in-out forwards 0.5s;

  &.dark-mode {
    background-color: $charcoal-blue;
  }
}

.overlay-settings {
  display: block;
  margin-top: $margin-xxs;
  background-color: $white;
  border-radius: 25px;
  box-shadow: rgb(0 0 0 / 5%) 0 0 15px 0;
  animation: fade-in ease-in-out forwards 0.2s;

  &.dark-mode {
    background-color: $charcoal-blue;
  }
}

.emoji-mart-category .emoji-mart-emoji {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  margin: 0.25em;
  overflow: hidden;
  font-size: 1.5em;
  line-height: 1.5em;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 0.25em;
  transition: all 300ms ease-in-out;

  &:hover {
    transform: scale(1.2);
  }
}

.emoji-mart-category .emoji-mart-emoji img {
  display: block;
  width: 100%;
  height: auto;
}

.fade-out {
  animation: fade-out forwards ease-in-out 0.5s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/* stylelint-disable declaration-no-important */
.loading-button {
  color: $sterling-grey !important;
  cursor: default !important;
  background-color: rgba($color: $icon-grey, $alpha: 50%) !important;
  /* stylelint-enable declaration-no-important */
}

.loading-button-spinner {
  position: absolute;
  width: 12px;
  height: 12px;
  border: 3px solid $green-blue;
  border-top-color: $darkest-blue;
  border-radius: 50%;
  animation: spinner 0.5s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

/* Quill editor darkmode styles */

quill-editor.dark-mode-content .ql-toolbar.ql-snow {
  background-color: rgb(137 198 219);
}

quill-editor.dark-mode-content .ql-picker-item {
  color: rgb(35 35 136);
}

quill-editor.dark-mode-content .ql-snow .ql-picker-options {
  color: $white;
  background-color: rgb(131 182 199);
}

quill-editor.dark-mode-content .ql-toolbar.ql-snow .ql-picker-label {
  color: $black;
}

quill-editor.dark-mode-content .ql-toolbar.ql-snow .ql-picker-label:hover {
  color: rgb(35 35 136);
}

.dark-mode .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: $charcoal-blue !important;
}

.dark-mode .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #45455780;
}

.dark-mode .ql-editor.ql-blank::before {
  color: $white;
}
